import React from "react"
import styled from "styled-components"

import { Heading } from "../components/styles"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const ContentArea = styled.div`
  background: ${props => props.alternate ? "#f2f2f2" : "#ffffff"};
  text-align: ${props => props.center ? "center" : "left"};
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const NotFoundPage = () => (
  <Layout logo="/hansogroup-logo-horizontal-light.svg">
    <Seo title="404: Not found" />
    <Hero title="Ooops..." image="matrix" />

    <ContentArea center>
      <Heading center>404 – NOT FOUND</Heading>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </ContentArea>
  </Layout>
)

export default NotFoundPage
